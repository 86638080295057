<template>
  <!-- modal -->

  <Modal :value="value" width="30vw" height="62vh" @input="closeModal">
    <!-- title -->

    <template #title>QR Image</template>

    <!-- ... -->

    <template #default>
      <div style="height: 40vh">
        <img :src="getURL" class="imgCenter" />
      </div>
      <div class="float-right">
        <BaseButton icon="eva-printer" label="print" @click="printImg()" />
      </div>
    </template>
  </Modal>

  <!-- ... -->
</template>
<script>
import Modal from "@/components/common/popup/Modal.vue";
export default {
  name: "QRView",

  components: {
    Modal,
  },

  props: {
    value: {
      type: Boolean,
      required: true,
    },

    formId: {
      type: String,
      default: "",
    },

    formEntryId: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      qrData: "",
      error: "",
    };
  },

  computed: {
    getURL() {
      if (this.$store.state.session) {
        return `${process.env.VUE_APP_API_URL}/form/qrview/${this.$store.state.session.tenantId}/${this.formId}/${this.formEntryId}`;
      }
      return "";
    },
  },

  methods: {
    closeModal() {
      this.$emit("input", false);
    },

    printImg() {
      let printWin = window.open(this.getURL, "_new");
      printWin.print();
    },
  },
};
</script>
<style scoped>
.imgCenter {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}
</style>
