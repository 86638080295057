<template>
  <BaseActionButton
    v-tooltip.bottom="'change theme'"
    is-flat
    :no-border="noBorder"
    :icon="darkTheme ? 'eva-moon-outline' : 'eva-sun-outline'"
    @click="toggleDarkTheme"
  />
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  name: "ThemeSwitcher",

  props: {
    noBorder: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    ...mapState(["darkTheme"]),
  },

  methods: {
    ...mapMutations(["toggleDarkTheme"]),
  },
};
</script>

<style lang="scss" scoped></style>
